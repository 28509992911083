<template>
	<div>
		<v-layout row wrap v-if="job">
			<v-flex xs6 px-2>
				<div style="height: 100vh; overflow-y: auto;" id="column1">
					<v-layout row wrap pt-2>
						<v-flex xs12 pl-2 pb-1>
							<small>
								<b>
									<div>This interface is only made for demo purpose. Our product is an API that returns all the data that you can see on this page.</div>
									<div>Reach out at contact@matching.hr</div>
								</b>
							</small>
						</v-flex>
						<v-flex xs12>
							<table border="1" :style="`width: ${selectedResumeId ? '100%' : '50%'};`" class="requirementsTable">
								<tr>
									<th class="px-2 py-3 leftCell">
										<h3>Job position & requirements</h3>
									</th>
									<th class="px-2 py-3 rightCell" v-if="selectedResumeId">
										<h3>Relevancy of the selected resume: {{ Math.round(selectedMatch.score) }}/10</h3>
									</th>
								</tr>
								<tr>
									<td class="px-2 py-3 leftCell">
										<div><b>Position: {{ job.specification.position.name }}</b></div>
										<small>
											<div class="mt-1"><b>Weight:</b> {{ job.specification.position.weight }}</div>
										</small>
									</td>
									<td class="px-2 py-3 rightCell" v-if="selectedResumeId">
										<div v-if="selectedMatch">
											<small>
												<div><b>Score: {{ selectedMatch.details.position.score }}</b></div>
												<div>
													<div><b>Pros:</b></div>
													<ul>
														<li v-for="(text, key) in selectedMatch.details.position.pros" :key="key">
															<b>{{ text }}</b>
														</li>
													</ul>
												</div>
												<div>
													<div><b>Cons:</b></div>
													<ul>
														<li v-for="(text, key) in selectedMatch.details.position.cons" :key="key">
															<b>{{ text }}</b>
														</li>
													</ul>
												</div>
											</small>
										</div>
									</td>
								</tr>
								<tr v-for="(requirement, requirementIndex) in job.specification.requirements" :key="requirementIndex">
									<td class="px-2 py-3 leftCell">
										<div><b>{{ requirement.name }}</b></div>
										<div class="mt-1">
											<v-chip small v-for="(skill, index) in requirement.skills" :key="index" class="mr-1">
												{{ skill }}
											</v-chip>
										</div>
										<small>
											<div class="mt-2" style="line-height: 15px">{{ requirement.description }}</div>
											<div class="mt-1"><b>Weight:</b> {{ requirement.weight }}</div>
										</small>
									</td>
									<td class="px-2 py-3 rightCell" v-if="selectedResumeId">
										<small v-if="selectedMatch">
											<div><b>Score:</b> {{ selectedMatch.details.requirements[requirementIndex].score }}</div>
											<div>
												<div><b>Pros:</b></div>
												<ul>
													<li v-for="(text, key) in selectedMatch.details.requirements[requirementIndex].pros" :key="key">
														{{ text }}
													</li>
												</ul>
											</div>
											<div>
												<div><b>Cons:</b></div>
												<ul>
													<li v-for="(text, key) in selectedMatch.details.requirements[requirementIndex].cons" :key="key">
														{{ text }}
													</li>
												</ul>
											</div>
										</small>
									</td>
								</tr>
							</table>
						</v-flex>

						<v-flex xs12 pt-12 mb-6>
							<v-card outlined class="px-2 py-2">
								<div><h3>Original job description</h3></div>
								<small>
									<div class="mt-2 text-pre-wrap">
										{{ job.text }}
									</div>
								</small>
							</v-card>
						</v-flex>
					</v-layout>
				</div>
			</v-flex>

			<v-flex xs4 px-2 v-if="job && job.matches">
				<div style="height: 100vh; overflow-y: auto;" id="column2">
					<div v-if="selectedMatch">
						<v-layout row wrap pt-5>
							<v-flex xs12 text-center>
								<h3>Selected resume</h3>
							</v-flex>
							<v-flex xs12 pt-1 pb-1>
								<v-btn outlined small color="primary" @click="togglePdfView">
									{{ showPdf ? 'Show resume as text' : 'Show original PDF resume' }}
								</v-btn>
							</v-flex>
						</v-layout>
						<v-card outlined>
							<v-card-text>
								<iframe v-if="pdfUrl"
									v-show="showPdf"
									:src="pdfUrl"
									width="100%"
									height="800px"
									frameborder="0"
								></iframe>
								<div v-show="!showPdf" class="markdown-content" v-html="compiledMarkdown"></div>
							</v-card-text>
						</v-card>
					</div>
				</div>
			</v-flex>

			<v-flex xs2 px-2 v-if="job && job.matches">
				<div style="height: 100vh; overflow-y: auto;">
					<v-layout row wrap pt-2>
						<v-flex xs12>
							<h3>Best 50 resumes for the job</h3>
							<b :class="{'orange--text': !selectedResumeId}">Select a resume below</b>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 v-for="(match, index) in job.matches" :key="index" py-2>
							<v-card class="px-1 py-2" outlined @click="selectedResumeId = match.resumeId" :style="selectedResumeId === match.resumeId ? 'border: 2px solid black' : '' ">
								<small>
									<b>
										<div>Resume rank: {{ index + 1 }}</div>
										<div>Global score: {{ Math.round(match.score) }}</div>
									</b>
									<div>Position score: {{ match.details.position.score }}</div>
									<div>Requirement scores: {{ match.details.requirements.map(x => x.score).join(', ') }}.</div>
									<div>Resume id: {{ match.resumeId }}</div>
								</small>
							</v-card>
						</v-flex>
					</v-layout>
				</div>
			</v-flex>

			<v-flex xs4 v-if="job && !job.matches">
				<v-layout row wrap justify-center pt-12 mt-12>
					<v-flex xs12 text-center mt-12 pt-12>
						<h3>Analyzing for each of the 2500+ resumes in the database how relevant it is for this job and why...</h3>
						<v-progress-linear class="my-2" indeterminate primary/>
						<small>Use this time to review the job requirements.</small>
					</v-flex>
				</v-layout>				
			</v-flex>
		</v-layout>

		<v-layout row wrap v-else justify-center>
			<v-flex xs6>
				<v-progress-linear indeterminate primary/>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
const functionsUrlBase = {
	'localhost': 'http://localhost:5001/matchinghr-dev/us-central1',
  'matchinghr-dev.firebaseapp.com': 'https://europe-west3-matchinghr-dev.cloudfunctions.net',
  'matchinghr-prod.firebaseapp.com': 'https://europe-west3-matchinghr-prod.cloudfunctions.net',
  'matching.hr': 'https://europe-west3-matchinghr-prod.cloudfunctions.net'
}[window.location.hostname]

const DEV_API_KEY = "1e2ab8433ea9aec3f7f18a4dd2ff1887"
const PROD_API_KEY = "b7450a0246c4a3574f9a1f54acbf92d1"
const API_KEY = {
	'localhost': DEV_API_KEY,
  'matchinghr-dev.firebaseapp.com': DEV_API_KEY,
  'matchinghr-prod.firebaseapp.com': PROD_API_KEY,
  'matching.hr': PROD_API_KEY
}[window.location.hostname]

import axios from 'axios'
import MarkdownIt from 'markdown-it'

export default {
  data() {
    return {
			jobId: null,
			job: null,
			selectedResumeId: null,
			showPdf: true,
			pdfUrl: null,
			pdfLoading: false,
			markdownParser: new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
				breaks: true
      }),
    };
  },
	mounted() {
		this.jobId = this.$route.params.jobId
		this.fetchJobRegularly()
	},
	computed: {
		selectedMatch() {
			if (this.selectedResumeId) {
				return this.job.matches.find(match => match.resumeId === this.selectedResumeId)
			} else {
				return null
			}
		},
		compiledMarkdown() {
			if (this.selectedMatch) {
				return this.markdownParser.render(this.selectedMatch.resume.markdown)
			} else {
				return null
			}
		},
	},
  methods: {
		async fetchJobRegularly() {
			await this.fetchJob()
			if (this.job.status !== 'done') {
				setTimeout(() => {
					this.fetchJobRegularly()
				}, 1000)
			}
		},
		async fetchJob() {
			const url = `${functionsUrlBase}/getJob`

			const headers = {
				'Content-Type': 'application/json',
				'X-API-Key': API_KEY,
			};

			const dataObject = {
				jobId: this.jobId
			}

			await axios.post(
				url, 
				dataObject,
				{ headers }
			)
				.then(response => {
					this.job = response.data.data.job
				})
				.catch(error => {
					try {
						console.error('Error:', error.response.data);
					} catch {
						console.error('Error:', error);
					}
				});
		},
    async togglePdfView() {
      this.showPdf = !this.showPdf;
    },
    async loadPdf() {
      if (this.pdfUrl) return;
      
      this.pdfLoading = true;
      try {
        const resumeRef = this.$storage.ref().child(`resumes/${this.selectedResumeId}.pdf`);
        this.pdfUrl = await resumeRef.getDownloadURL();
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.showPdf = false;
      } finally {
        this.pdfLoading = false;
      }
    },
		disableIframeHistory() {
			const iframe = document.querySelector('iframe');
			if (iframe) {
				iframe.addEventListener('load', () => {
					if (history.length > 1) {
						history.pushState(null, '', window.location.href);
					}
				});
			}
		}
	},
	watch: {
		async selectedResumeId() {
			document.getElementById('column1').scrollTo(0,0)
			document.getElementById('column2').scrollTo(0,0)
      // Reset PDF state when changing resume
      // this.showPdf = false;
      this.pdfUrl = null;
			await this.loadPdf();
		},
		pdfUrl: {
			handler(newUrl) {
				if (newUrl) {
					this.disableIframeHistory()
				}
			},
			immediate: true
		},
		showPdf: {
			handler(val) {
				if (val) {
					this.disableIframeHistory()
				}
			},
			immediate: true
		},
	}
}
</script>

<style scoped>
	.text-pre-wrap {
		white-space: pre-wrap;
	}
	.requirementsTable {
		border-collapse: collapse;
		border: 1px solid #e0e0e0;
	}
	.leftCell {
		width: 50%;
		border: 1px solid #e0e0e0;
	}
	.rightCell {
		width: 50%;
		border: 1px solid #e0e0e0
	}

</style>

<style>
	.markdown-content h1,
	.markdown-content h2,
	.markdown-content h3,
	.markdown-content h4,
	.markdown-content h5,
	.markdown-content h6 {
		margin-top: 1em;
		/* margin-bottom: 0.75em; */
	}
	.markdown-content p {
		margin-bottom: 0;
	}
</style>