<template>

	<div>

		<v-layout row wrap style="height:100vh; background-color: white" justify-center>
      <v-flex xs11 :class="{'px-12': !$vuetify.breakpoint.xs, 'px-0 pb-12': $vuetify.breakpoint.xs}" :style="$vuetify.breakpoint.xs ? `padding-top:8vh` : `padding-top:11vh`">
        <v-card class="pb-12 pt-12 mb-12" elevation="24" style="border: 2px solid black; border-radius: 50px">

					<v-layout row wrap justify-center v-if="!isCreateJobPending">
						<v-flex xs12 mb-12 mt-3 text-center>
							<h1>Job-Resumes Matching Demo</h1>
							<div>
								<small>See how our matching algorithm identifies for any job the best 50 resumes from a resume database of any size.</small>
							</div>
						</v-flex>
						<v-flex xs11 sm8 mt-4>
							<v-layout row wrap>
								<v-flex xs12>
									<div><b>Paste a job description</b></div>
									<small>
										<div>Go to a job portal like <a href="https://indeed.com" target="_blank">Indeed</a> and copy-paste the full content of any job description, or just<v-btn class="ml-1 mb-1" @click="prefillJobTextWithExample" outlined small>use our job description example</v-btn></div>
									</small>
								</v-flex>
								<v-flex xs12 mt-2>
									<v-textarea v-model="jobText" label="Paste a full job description here..." auto-grow outlined :rows="6"  hide-details class="body-2 dense-text" style="line-height: 1.2;" />
								</v-flex>
								<v-flex xs12 mt-12>
									<div>
										<b>Language of the explanations</b>
										<v-tooltip right dark>
											<template v-slot:activator="{ on, attrs }">
												<span v-bind="attrs" v-on="on">
													<v-icon class="ml-1 mb-1" color="primary" small>mdi-information</v-icon>
												</span>
											</template>
											<ul class="my-3">
												<li>This is the language in which you want to display explanations about why a resume was selected for the job.</li>
												<li>Matching.HR is cross-language: the languages of the job, of the resumes and of the explanations, can all be different without any problem.</li>
											</ul>
										</v-tooltip>
									</div>
									<v-layout row wrap mt-2>
										<v-flex xs3>
											<v-autocomplete
												v-model="detailsLanguage"
												:items="allLanguages"
												item-text="name"
												item-value="code"
												outlined
												clearable
												dense
											></v-autocomplete>
										</v-flex>
									</v-layout>
								</v-flex>
								<v-flex xs12 mb-6 mt-6 text-center>
									<v-btn @click="createJob" class="primary mt-6" large>Get top resumes for this job</v-btn>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>


					<v-layout row wrap text-center justify-center v-if="isCreateJobPending">
						<v-flex xs11 sm5 mb-12 pb-12 mt-12 pt-6>
							<h3 class="mb-2">Extracting position and requirements from job...</h3>
							<v-progress-linear indeterminate/>
						</v-flex>
					</v-layout>

				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import axios from 'axios'

const functionsUrlBase = {
  'localhost': 'http://localhost:5001/matchinghr-dev/us-central1',
  'matchinghr-dev.firebaseapp.com': 'https://europe-west3-matchinghr-dev.cloudfunctions.net',
  'matchinghr-prod.firebaseapp.com': 'https://europe-west3-matchinghr-prod.cloudfunctions.net',
  'matching.hr': 'https://europe-west3-matchinghr-prod.cloudfunctions.net'
}[window.location.hostname]

const DEV_API_KEY = "1e2ab8433ea9aec3f7f18a4dd2ff1887"
const PROD_API_KEY = "b7450a0246c4a3574f9a1f54acbf92d1"
const API_KEY = {
  'localhost': DEV_API_KEY,
  'matchinghr-dev.firebaseapp.com': DEV_API_KEY,
  'matchinghr-prod.firebaseapp.com': PROD_API_KEY,
  'matching.hr': PROD_API_KEY
}[window.location.hostname]

const allLanguages = [["Afrikaans","af"],["Albanian","sq"],["Amharic","am"],["Arabic","ar"],["Armenian","hy"],["Assamese","as"],["Azerbaijani","az"],["Basque","eu"],["Belarusian","be"],["Bengali","bn"],["Bosnian","bs"],["Bulgarian","bg"],["Catalan","ca"],["Cebuano","ceb"],["Chinese","zh"],["Corsican","co"],["Croatian","hr"],["Czech","cs"],["Danish","da"],["Dhivehi","dv"],["Dutch","nl"],["English","en"],["Esperanto","eo"],["Estonian","et"],["Filipino (Tagalog)","fil"],["Finnish","fi"],["French","fr"],["Frisian","fy"],["Galician","gl"],["Georgian","ka"],["German","de"],["Greek","el"],["Gujarati","gu"],["Haitian Creole","ht"],["Hausa","ha"],["Hawaiian","haw"],["Hebrew","iw"],["Hindi","hi"],["Hmong","hmn"],["Hungarian","hu"],["Icelandic","is"],["Igbo","ig"],["Indonesian","id"],["Irish","ga"],["Italian","it"],["Japanese","ja"],["Javanese","jv"],["Kannada","kn"],["Kazakh","kk"],["Khmer","km"],["Korean","ko"],["Krio","kri"],["Kurdish","ku"],["Kyrgyz","ky"],["Lao","lo"],["Latin","la"],["Latvian","lv"],["Lithuanian","lt"],["Luxembourgish","lb"],["Macedonian","mk"],["Malagasy","mg"],["Malay","ms"],["Malayalam","ml"],["Maltese","mt"],["Maori","mi"],["Marathi","mr"],["Meiteilon (Manipuri)","mni-Mtei"],["Mongolian","mn"],["Myanmar (Burmese)","my"],["Nepali","ne"],["Norwegian","no"],["Nyanja (Chichewa)","ny"],["Odia (Oriya)","or"],["Pashto","ps"],["Persian","fa"],["Polish","pl"],["Portuguese","pt"],["Punjabi","pa"],["Romanian","ro"],["Russian","ru"],["Samoan","sm"],["Scots Gaelic","gd"],["Serbian","sr"],["Sesotho","st"],["Shona","sn"],["Sindhi","sd"],["Sinhala (Sinhalese)","si"],["Slovak","sk"],["Slovenian","sl"],["Somali","so"],["Spanish","es"],["Sundanese","su"],["Swahili","sw"],["Swedish","sv"],["Tajik","tg"],["Tamil","ta"],["Telugu","te"],["Thai","th"],["Turkish","tr"],["Ukrainian","uk"],["Urdu","ur"],["Uyghur","ug"],["Uzbek","uz"],["Vietnamese","vi"],["Welsh","cy"],["Xhosa","xh"],["Yiddish","yi"],["Yoruba","yo"],["Zulu","zu"]]

const jobDescriptionExample = `iAdvize
Business Data Analyst - US Market (F/H/X)
Permanent contract
Boston, Nantes, Paris
Salary:
Not specified
Starting date:
January 20, 2025
Fully-remote
Experience:
> 4 years
Education:
Master's Degree
Apply

Save
12 days ago


Share

iAdvize
iAdvize
Interested in this job?
Apply

Save
Questions and answers about the job

Does this position allow remote work?

What type of contract is offered for this position?

What is the start date of the contract?
The position
Job description
Company description
As a provider of a complete solution for pre-sales and customer service, iAdvize has had a single mission since its creation in 2010: to make brands conversational.

iAdvize is a conversational commerce pioneer and the leader in trusted generative AI for e-commerce. We offer safe, compliant, and connected AI copilots to boost conversion rates 10x while automating at least 75% of the workload. Innovative brands like Cdiscount, Samsung, and Nespresso use iAdvize to simplify online shopping and generate $1B+ per year in online revenue.

To deliver this experience at scale, we deploy AI-powered virtual copilots that are reliable, connected and fully compliant :

iAdvize Copilot™ for Shoppers : Boost online sales 24/7 with an AI-powered shopping assistant. Remove purchase disincentives and increase conversions.
iAdvize Copilot™ for Agents : Increase the speed, efficiency and quality of service of your advisors with copilots powered by trusted generative AI.
iAdvize is also more than 200 talents present in Nantes (our headquarters), Paris (FR), Düsseldorf (DE) and Boston (US). Convinced that diversity is a major asset for our teams, we are proud to have over 45% women in our workforce.

We are also convinced that sustainable growth can only be achieved by taking into account ambitious social and environmental objectives. In 2022, we launched a multi-disciplinary working group on CSR issues, with the aim of reducing our carbon emissions, forging responsible partnerships and supporting the conversational transformation of e-commerce towards a more sustainable model.

You can find more information and photos of our premises on our Welcome to the jungle page.



What’s the opportunity?
iAdvize is hiring a Business Data Analyst to support key US-based accounts, with a strong emphasis on performance reporting and value demonstration.

The role will report directly to the Head of Data, located in Nantes, but will be embedded in the US Customer Service team for daily operations.

This transversal role is uniquely placed to infuse a shared methodology into business practices and bring a deep understanding of key client needs and context back to the technical teams.

Flexibility to adapt to the US timezone and fluency in English are a must for this role.



What will be my missions?
1. Conduct ad-hoc analyses for clients to diagnose and address performance issue

2. Maintain/update/develop Tableau dashboards to track performance metrics for US clients

3. Update, export, and distribute daily reports for key accounts.

4. Help conceive and monitor AB test setups in coordination with the core Data team

5. Present analyses in client-facing meetings to clearly communicate insights and recommendations



What skills do I need?
Minimum of 4 years of work experience in data analysis / BI.
Proficiency in data visualization and associated tools such as Tableau Software or equivalent.
Ability to independently query cloud-based databases and data warehouses (knowledge of SQL) to gather the necessary data for analysis.
Knowledge of AB testing in an e-commerce environment, including KPIs, ideal setups, best practices, and result interpretation.
Demonstrated ability to understand client business needs and translate data into actionable insights that align with and achieve business objectives.
Strong collaborative skills, with a proven track record of working effectively across teams and departments, and excellent communication skills for liaising with clients and internal stakeholders.
Excellent English Skills : Fluent in spoken and written English, comfortable with active communication to effectively support US clients.
US Timezone Support (East Coast): ability to work partially shifted hours while based in France, ensuring coverage for urgent requests from key accounts with near real-time responsiveness.
Product acumen (understanding of product challenges, roadmap, organization) ideally acquired through past experiences with SaaS companies.


Bonus points
1. Strong understanding and experience in the SaaS industry and B2B environments.

2. Proficiency in Tableau Software is a strong plus.

3. Experience with product and web analytics tools (e.g., Mixpanel, Amplitude, or equivalent). 

4. Familiarity with Google Analytics is particularly valued for tracking and analyzing AB testing.

5. Knowledge of Python to perform advanced analyses when necessary


Additional informations
Contract : CDI
Ideal working hours : 12h - 21 h
Place of work : based in France, Fullremote with occasional travel to Nantes (HQ)
Start date : as soon as possible
Joining iAdvize means...
Participating in the development of an ambitious, international and dynamic French scale-up, a pioneer in trust-generating AI.
Joining a group of new recruits, and taking part in a week-long onboarding programme that will train you on our product, our strategy, our market and our sales techniques.
Work in an agile environment, where you will be able to contribute your own ideas.

iAdvize is committed to the diversity of its teams and applies a strict policy of non-discrimination to recruitment.`

export default {
  data() {
    return {
			jobText: '',
			detailsLanguage: 'en',
			isCreateJobPending: false,
			allLanguages: allLanguages.map(x => {
				return {
					name: x[0],
					code: x[1]
				}
			})
    };
  },
	mounted() {
		
	},
  methods: {
		createJob() {
			if (!this.jobText.trim()) return;
			
			this.isCreateJobPending = true

			const url = `${functionsUrlBase}/createJob`

			const headers = {
				'Content-Type': 'application/json',
				'X-API-Key': API_KEY,
			};

			const dataObject = {
				jobText: this.jobText,
				detailsLanguage: this.detailsLanguage || 'en',
			}

			axios.post(
				url, 
				dataObject,
				{ headers }
			)
				.then(response => {
					this.$router.push(`/demo/view-job/${response.data.data.jobId}`)
				})
				.catch(error => {
					this.isCreateJobPending = false
					try {
						console.error('Error:', error.response.data);
					} catch {
						console.error('Error:', error);
					}
				});
		},
		prefillJobTextWithExample() {
			this.jobText = jobDescriptionExample
		}
	}
}

</script>

<style>
.dense-text textarea {
  line-height: 1.2 !important;
}
</style>

<style scoped>
.v-input--selection-controls {
	margin-top: 0;
}
</style>


<style scoped>
.v-tooltip__content {
  background-color: #37474f;
  opacity: 1 !important;
}
</style>