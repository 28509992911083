<template>
  <div>
    <v-layout row wrap style="height:100vh; background-color: white" justify-center>
      <v-flex xs11 :class="{'px-12': !$vuetify.breakpoint.xs, 'px-0 pb-12': $vuetify.breakpoint.xs}" :style="$vuetify.breakpoint.xs ? `padding-top:8vh` : `padding-top:11vh`">
        <v-card class="pb-12 pt-12" elevation="24" style="border: 2px solid black; border-radius: 50px">
          <v-layout row wrap pt-3 :class="{'mx-12 px-12': !$vuetify.breakpoint.xs, 'mx-8': $vuetify.breakpoint.xs,}" >
            <v-flex xs12>
              <b :style="!$vuetify.breakpoint.xs ? `font-size: 4em` : `font-size: 3em`">Matching.HR</b>
            </v-flex>
            <v-flex xs12 mt-12>
              <b style="font-size: 2em">We provide a white-label job-resume matching algorithm to major recruiting software companies.</b>
            </v-flex>
            <v-flex xs12 mt-6>
              <b style="font-size: 1.6em">Display to your users the top candidates for each job, with intelligent explanations.</b>
            </v-flex>
            <v-flex xs12 mt-6>
              <b style="font-size: 1.6em">Available via a simple plug-and-play API.</b>
            </v-flex>
            <v-flex xs12 mt-12 pt-3 text-center>
              <v-btn to="/demo" class="primary" elevation="0" x-large>Try the online demo</v-btn>
              <div><small><i>No sign-up required.</i></small></div>
            </v-flex>
            <v-flex xs12 mt-6 mb-3>
              <b style="font-size: 1.4em">Let's talk !</b><br>
              <a style="font-size: 1.4em" :href="mailtoHref">contact@matching.hr</a>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <!-- <v-flex xs12 sm6 text-center pt-7 v-if="!$vuetify.breakpoint.xs">
        <a :href="mailtoHref"><img :src="images.logo" height="60%" style="padding-top:10vh"></a>
      </v-flex> -->

    </v-layout>
    <v-layout v-if="!$vuetify.breakpoint.xs" row wrap ml-12 :class="{'pl-12': !$vuetify.breakpoint.xs}" >
      <v-flex xs12 pl-12>
        <div :style="`position: absolute; bottom: 5px;`">
          <small>
            <div>
              <span>Made in Paris, France. – Matching.HR – &copy; Copyright {{ new Date().getFullYear() }} – GDPR compliant.</span>
            </div>
          </small>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import axios from "axios";

// const functionsUrlBase = {
//   localhost: "http://localhost:5001/matchinghr-dev/europe-west3",
//   "matchinghr-dev.firebaseapp.com": "https://europe-west3-matchinghr-dev.cloudfunctions.net",
//   "matchinghr-prod.firebaseapp.com": "https://europe-west3-matchinghr-prod.cloudfunctions.net",
//   "matching.hr": "https://europe-west3-matchinghr-prod.cloudfunctions.net",
// }[window.location.hostname];

export default {
  components: {},
  data() {
    return {
      images: {
        logo: require('@/assets/app/images/logo_square.png'),
      },
      mailtoHref: this.getMailToHref()
    }
  },
  methods: {
    getMailToHref() {
      const subject = `Inquiry for Matching.HR`
      const content = `Hello,

We want to provide our users with a better way to identify the right candidates for their jobs.

Would you be available for a video call soon ?

Here are my availabilities:
- 
-
-

Best regards,`
      return `mailto:contact@matching.hr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(content)}`
    }
  }
};
</script>

<style>
.textAsLink {
  text-decoration: underline !important;
  cursor: pointer !important;
}
</style>